input[type=file]::file-selector-button {
    font-weight: bold;
    background: #0453e7;
    padding: 0.5em;
    color: whitesmoke;
    border: thin solid grey;
    border-radius: 4px;
    cursor: pointer;
}

.inputfile{
    width: 100%;
    display: flex;
    align-items: center;
    align-content: stretch;
    margin-top: 10px;
    border:1px solid lightgray;
    border-radius:4px;
    background-color: inherit;
    color: black;
}




