.mask{
        opacity: 0.7;
            width: 100%;
            left: 0;
            top: 0;
            height: 100%;
            position: fixed;
            z-index: 99999;
            pointer-events: none;
            color: #000;
}
.helper {
    --reactour-accent: #5cb7b7;
    line-height: 1.3;
    color: #2d2323;
}