@font-face{
    font-family: "poppin";
    src: local("poppin"),url(../font/Poppins-Regular.ttf) format("woff");
}
@font-face{
    font-family: "poppinmedium";
    src: local("poppinmedium"),url(../font/Poppins-Medium.ttf) format("woff");
}
@font-face{
    font-family: "poppinsemibold";
    src: local("poppinsemibold"),url(../font/Poppins-Bold.ttf) format("woff");
}