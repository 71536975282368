.rtf-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #dee5ec;
  border-radius: 8px;
  max-height: 180px;
}

.rtf-wrapper-with-direction {
  display: flex;
  flex-direction: column;
  border: 1px solid #dee5ec;
  max-height: 180px;
  border-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.rtf-toolbar {
  order: 2;
  padding: 12px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 1px solid #dee5ec;
  margin-bottom: 0px;
  gap: 8px;
}

.rtf-toolbar-with-direction-control {
  order: 2;
  padding: 12px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top: 1px solid #dee5ec;
  margin-bottom: 0px;
  gap: 8px;
}

.rtf-editor {
  padding: 12px;
  border-radius: 8px;
}

.rtf-toolbar-inline,
.rtf-toolbar-list {
  margin: 0px;
  gap: 8px;
}

.rtf-toolbar-inline .rdw-option-wrapper,
.rtf-toolbar-list .rdw-option-wrapper {
  border-radius: 2px;
  border: 0px;
  padding: 2px 0px;
  margin: 0px;
  min-width: 25px;
}

.rtf-toolbar-inline .rdw-option-wrapper:hover,
.rtf-toolbar-list .rdw-option-wrapper:hover {
  box-shadow: none;
  background-color: #dee5ec;
}

.rtf-toolbar-inline .rdw-option-active,
.rtf-toolbar-list .rdw-option-active {
  background-color: #dee5ec;
  box-shadow: none;
}

/* Material UI Button Classes */
.button-label {
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
